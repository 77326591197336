<template>
    <PopoverPortal to="#root">
        <PopoverContent v-bind="{ ...forwarded, ...$attrs }" :class="popoverContentClass">
            <slot />
        </PopoverContent>
    </PopoverPortal>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
    PopoverContent,
    type PopoverContentEmits,
    type PopoverContentProps,
    PopoverPortal,
    useForwardPropsEmits,
} from "reka-ui";

defineOptions({ inheritAttrs: false });

const props = withDefaults(defineProps<PopoverContentProps & { class?: HTMLAttributes["class"] }>(), {
    align: "center",
    sideOffset: 4,
    class: "",
});
const emits = defineEmits<PopoverContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;
    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const POPOVER_CONTENT_CLASS =
    "z-50 w-72 rounded-md border border-gray-200 bg-white p-2 text-gray-950 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2";
const popoverContentClass = computed(() => cn(POPOVER_CONTENT_CLASS, props.class));
</script>
